<template>
  <div>
    <PageHeader title="Binnenkomend gesprek" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <template v-if="noContactFound">
        <div class="alert alert-info">
          Er kon geen bestaand contact met het telefoonnnummer <strong>{{ phoneNumber }}</strong> gevonden worden.
        </div>

        <ContactCreateModal
          :values="values"
          :modal="false"
          class="tw-p-6 tw-bg-white tw-rounded tw-shadow-lg"
        />
      </template>

      <template v-else>
        <div class="alert alert-success">
          Er is een binnenkomend gesprek van <strong>{{ phoneNumber }}</strong>, we hebben volgende contacten in IAM gevonden met dit telefoonnummer.
        </div>

        <DataTable
          v-model="selectedRecords"
          :loading="loading"
          :headers="HEADERS"
          :can-select="true"
          :select-record-object="true"
          v-bind="contacts"
          @changePage="loadContacts"
        >
          <template #toolbar>
            <div class="tw-mb-2 tw-flex tw-justify-end">
              <button
                type="button"
                :title="mergeContactError || 'Contacten samenvoegen'"
                :disabled="mergeContactError"
                class="action tw-bg-success"
                @click="mergeContacts"
              >
                <i class="far fa-compress-alt" /> Contacten samenvoegen
              </button>
            </div>
          </template>

          <template #item-type="{ item }">
            <i :class="['fa', item.type === 'B' ? 'fa-users' : 'fa-user']" />
            {{ item.type === 'B' ? 'Bedrijf' : 'Persoon' }}
          </template>
          <template #item-phone="{ item }">
            <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
          </template>
          <template #item-mobile="{ item }">
            <a :href="`tel:${item.mobile}`">{{ item.mobile }}</a>
          </template>
        </DataTable>
      </template>
    </div>
    <ContactMergeModal
      ref="mergeModal"
      @contacts-merged="redirectToMergedContact"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable.vue'

import ContactMergeModal from '@/components/contacts/ContactMergeModal'
import ContactCreateModal from '@/components/contacts/ContactCreateModal.vue'
import ContactName from '@/components/contacts/contact_render_components/ContactName'
import ContactAddress from '@/components/contacts/contact_render_components/ContactAddress'

import { getContacts } from '@/services/contacts'
import { errorModal } from '@/modalMessages'

import { formatPhone, parsePhoneNumber } from '@/utils/helpers'

export default {
  name: 'ContactConnector',
  components: {
    PageHeader,
    DataTable,
    ContactMergeModal,
    ContactCreateModal
  },
  constants: {
    HEADERS: [
      { text: 'Naam', value: 'id', renderComponent: ContactName },
      { text: 'Type', value: 'type' },
      { text: 'Adres', value: 'address', renderComponent: ContactAddress },
      { text: 'Telefoon', value: 'phone' },
      { text: 'Gsm', value: 'mobile' }
    ]
  },
  data () {
    return {
      loading: true,
      // The contacts object includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      contacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      noContactFound: false,
      values: {},
      selectedRecords: []
    }
  },
  computed: {
    phoneNumber () {
      // URL query parameter needs to be one of the following:
      // - 0479121314
      // - %2B32479121314 (%2B is the encoding for a plus sign)
      // - 0032479121314
      const phone = this.$route.query.phone?.replace(/\s/g, '') // Remove whitespace

      const { dialCode, number } = parsePhoneNumber(phone)
      return formatPhone(dialCode, number)
    },
    mergeContactError () {
      const rangeValid = this.selectedRecords.length > 1 && this.selectedRecords.length < 4
      if (!rangeValid) return 'Enkel mogelijk voor 2 of 3 contacten'

      const contactTypesSame = this.selectedRecords.every(
        (contact, i, contactsToMerge) => contact.type === contactsToMerge[0].type
      )
      if (!contactTypesSame) return 'Enkel mogelijk voor contacten van hetzelfde type'

      return false
    }
  },
  watch: {
    phoneNumber: {
      immediate: true,
      handler (phone) {
        this.resetSelections()
        this.findContact(phone)
      }
    }
  },
  methods: {
    redirectToMergedContact (contactId) {
      this.$router.push({ name: 'ContactDetails', params: { id: contactId } })
    },
    resetSelections () {
      this.selectedRecords = []
    },

    async loadContacts (payload) {
      const response = await getContacts(payload)
      this.contacts = response.data
      return response.data
    },

    async findContact (phone) {
      try {
        this.loading = true
        this.noContactFound = false

        const params = { phone }
        const contacts = await this.loadContacts({ params })
        const { count, results } = contacts

        if (!count) {
          this.noContactFound = true
          const fieldName = phone.length > 10 ? 'mobile' : 'phone'
          this.values = { [fieldName]: phone }
        } else if (count === 1) {
          const [{ id }] = results
          this.$router.push({ name: 'ContactDetails', params: { id } })
        } else this.contacts = contacts

        this.loading = false
      } catch (error) {
        console.log(error)
        errorModal('Fout bij zoeken naar contact met telefoon, probeer het opnieuw.')
      }
    },
    async mergeContacts () {
      const response = this.$refs.mergeModal.init(this.selectedRecords.map(obj => obj.id))
      return response
    }
  }
}
</script>
